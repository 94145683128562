.contact {
  margin: 7em auto;
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;

  img {
    width: 400px;
  }

  .contact_text {
    width: 50%;

    form {
      margin-top: 3em;
      display: flex;
      flex-direction: column;

      input,
      select,
      textarea,
      option,
      button {
        font-family: inherit;
        font-size: 15px;
        border: 1px solid rgb(233, 233, 232);
        border-radius: 5px;
        padding: 4px 0;
        color: black;

        &:focus,
        &:active,
        &::selection {
          border-color: #cc4124;
        }
      }

      textarea,
      input {
        padding-left: 10px;
      }

      label,
      button {
        margin: 2% 0;
      }

      button {
        background-color: lightgray;
        color: black;
        &:hover,
        &:active {
          background-color: #cc4124;
          border-color: #cc4124;
          color: white;
          cursor: pointer;
        }
      }
    }

    p {
      text-align: left;
    }
  }
}

@media only screen and (min-device-width: 300px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 1) {
  .contact {
    margin: 2em auto;
    img {
      margin-top: 3em;
      margin-bottom: 1em;
      width: 300px;
      border-radius: 5px;
    }
    .contact_text {
      width: 100%;

      p {
        width: 100%;
        margin-bottom: 15%;
      }
    }
  }
}
