.section {
  width: 100%;
  position: relative;
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  h1 {
    z-index: 20;
    margin-bottom: 2em;
  }

  .images {
    margin-bottom: 110px;
    width: 90%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    img {
      width: auto;
      height: 550px;
      margin: 1em 0.5em;
    }
  }

  button {
    font-family: "Didact Gothic", sans-serif;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    right: 20px;
    border: none;
    margin: 50px;
    padding: 10px 20px;
    font-size: 20px;
    background-color: transparent;
  }

  .home_btn {
    left: 0;
    text-align: left;
  }

  .next_btn {
    right: 0;
    text-align: right;
  }
}

@media only screen and (max-device-width: 812px) {
  .section {
    h1 {
      margin-bottom: 1em;
    }

    .images {
      margin-bottom: 6em;
      img {
        align-self: flex-start;
        width: 100%;
        height: auto;
      }
    }

    button {
      right: 0;
      padding: 0;
      margin: 50px 22px;
      font-size: 18px;
    }
  }
}
