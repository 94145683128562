.footer {
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;


    p {
        margin: 0.5em;
        font-size: small;
        
        a {
            color:black;
            text-decoration: none;
            
            &:hover {
                color: #cc4124;
            }
        }
    }
}