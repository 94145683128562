@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap');

body {
  margin: 0;
  position: relative;
  min-height: 100%;
  width: 100%;
  padding-bottom: 10vh;
  background-color: #ffffff;
  font-family:'Didact Gothic', sans-serif;
}